@import '../../index.scss';

.row {
    width: 98.5%;
    height: -moz-fit-content;
    height: fit-content;
    margin: 5px;
    background: $primary-color-evenLighter;
    border-radius: 5px;
    min-height: 50px;
    // user-select: none;
}

.row-mobile {
    width: 94.5%;
    height: -moz-fit-content;
    height: fit-content;
    margin: 5px;
    margin-top: 20px;
    background: $primary-color-evenLighter;
    border-radius: 5px;
    min-height: 50px;
    // user-select: none;
}

.row.selected, .row-mobile.selected {
    background: $primary-color-lighter;
}

.left {
    display: inline-block;
    padding: 5px;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    text-align: center;
    overflow-wrap: break-word;
    color: $color-gray-dark;
}

.center {
    display: inline-block;
    overflow-wrap: break-word;
    padding: 5px;
    padding-top: 8px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: $color-gray-dark;
}

.right {
    float:right;
    overflow-wrap: break-word;
    padding: 5px;
    padding-top: 8px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: $color-gray-dark;
}

.second-row {
    padding-top: 3px;
    font-weight: bold;
}

.edit-icon {
    float:right;
    margin-right: 10px;
    height: 37px;
    width: 37px;
    padding-top: 0;
    border-radius: 50%;
    border: none;
}

.edit-icon:hover {
    box-shadow: 0 0 6px -1px rgba(0,0,0,0.6);
    cursor:pointer;
}