

.enable-background {
    enable-background:new 0 0 50 50;
}


.loader{
  
    margin: 0 0 2em;
    height: 100px;
    width: 100%;
    text-align: center;
    padding: 1em;
    margin: 0 auto 1em;
    display: inline-block;
    vertical-align: top;
    
    svg path,
    svg rect{
      fill: rgba(0, 127, 255, 1);
    }
  }
