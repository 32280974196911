@import '../../../index.scss';

.data {
    margin: 0.5% 0% 1.5% 1%;
    font-weight: bold;
    font-size: 18px;
    height: fit-content;
}

.data-mobile {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  margin: 12px 0% 10px 10px;
}

.normal-size {
    width: 100%;
}

.half-size {
    margin: 1%;
    width: 48%;
    display: inline-table;
    height: fit-content;

    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
      color: #29333c;
      margin-left: 1%;
    }

    .title-placeholder {
        display: inline;
        padding: 10px;
        line-height: 28px;
        color: $disabled-text;
        font-style: italic;
        font-weight: normal;
    }
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #29333c;
  margin-left: 1%;
}

.arrow {
    float: right;
    display: inline;
    transform: translate(-50%, 50%);
}

.arrow-small {
    float: right;
    display: inline;
    transform: translate(-40%, 80%);
    width: 23px;
    height: 13px;
}

.collapsibleContainer {
    padding: 0;
  }
  
  .collapsibleContainer:global(__trigger) {
    display: block;
    position: relative;
    padding: 20px 0;
    cursor: pointer;
    font-family: 'URW Gothic', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 18px;
    font-weight: 500;
  }
  
  .collapsibleContainer:global(__trigger):after {
    font-family: 'FontAwesome';
    content: '\f107';
    position: absolute;
    right: 10px;
    top: 50%;
    display: block;
    transition: transform 300ms;
    transform: translateY(-50%);
  }
  
  :local .collapsibleContainer:global(__trigger):global(.is-open):after {
    transform: translateY(-50%) rotateZ(180deg);
  }
  
  :local .collapsibleContainer:global(__trigger):global(.is-disabled) {
    display: none;
  }
  
  :local .collapsibleContainer:global(__trigger):global(.is-closed) {
    border-bottom: solid 1px #bcc2d1;
  }