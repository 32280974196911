
.card {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    padding: 10px;
    background: #F2F9FF;
    // box-shadow: 9px 8px 24px -1px #D8D9DA;
    box-shadow: 9px 8px 24px -1px #bababb;
    border-radius: 10px;
    min-height: 120px;
}

// .arrow {
//     // margin-top: -22px;
//     // top: 50%;
//     // left: 50%;
//     // -webkit-transform: translate(-50%, -50%);
//     // transform: translate(-50%, -50%);
// }

.top-left-header {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #29333C;
}

.header {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #29333C;
}

.content {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #29333C;
}