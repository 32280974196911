
.container {
    box-shadow: 10px 10px  5px rgba(0,0,0,0.15);
    -moz-box-shadow: 10px 10px  5px rgba(0,0,0,0.15);
    -webkit-box-shadow: 10px 10px  5px rgba(0,0,0,0.15);
    -o-box-shadow: 10px 10px  5px rgba(0,0,0,0.15);
    background: white;
    border-radius: 20px;
    padding: 10px;
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 1%;
    margin-bottom: 15px;
    transition: all 0.4s;
    height: fit-content;
}

.header {
    font-weight: bold;
    display: block;
    margin-top: 1%;
    font-size:x-large;
}

.description {
    display: block;
    margin-bottom: 1%;
    font-size:large;
    
}