.item-detail-column {
    display: flex;
    font-size: 18px;
}

.item-detail-column > div,
 .item-detail-column > input,
 .item-detail-column > select {
    text-align: center;
    padding: 2px;
    margin: 3px;
}

.item-detail-column :nth-child(1) {
    flex: 3 3 0px;
    text-align: left;
}
.item-detail-column :nth-child(3) {
    flex: 1 1 0px;
}
.item-detail-column :nth-child(5) {
    flex: 1 1 0px;
}
.item-detail-column :nth-child(7) {
    flex: 1 1 0px;
}
.item-detail-column :nth-child(9) {
    flex: 1 1 0px;
}
.item-detail-column :nth-child(11) {
    flex: 1 1 0px;
}
.item-detail-column :nth-child(13) {
    flex: 1 1 0px;
}

.item-detail-column div:empty{
    pointer-events: none;
}

.item-detail-header {
    font-size: 18px;
    margin-top: 10px;
    font-weight: bold;
}

.item-detail-header-mobile {
    font-weight: bold;
    font-size: 11px;
    line-height: 13px;
    text-align: right;
    float: right;
    width: 100%;
}

.item-detail-button {
    float: right;
    margin-top: -48px;
}

.break-overflow {
    overflow-wrap: break-word;
}

.checkbox-mobile-fix {
    padding-top: 0px;
    margin-bottom: 16px;
}