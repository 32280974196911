
.original {
    z-index: 1001;
    position: fixed;
    bottom: 40px;
    right: 25px;
}

.not-first-render {
    animation-name: slideIn;
    animation-duration: 0.3s;
}

.moved {
    bottom: 20px;
    right: 15px;
    animation-name: slideOut;
    animation-duration: 0.3s;
  }
  
@keyframes slideOut {
    from {
        bottom: 40px;
        right: 25px;
    }
    to {
        bottom: 20px;
        right: 15px;
    }
}

@keyframes slideIn {
    from {
        bottom: 20px;
        right: 15px;
    }
    to {
        bottom: 40px;
        right: 25px;
    }
}