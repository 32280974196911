@import '../../../index.scss';

.button-container-time {
    // position: relative;
    z-index: 0;
    // margin-top: 10%;
}

.second-input {
    width: 29%;
    min-height: 30px;
    border: 2px solid $color-gray-light;
    color: $disabled-text;
    border-radius: 5px;
    // position: absolute;
    left: 63%;
    top: -20px;
}


.second-input-mobile {
    width: 100%;
    height: 32px;
    border: 1px solid #D6D6D6;
    color: $disabled-text;
    padding: 5px;
    margin-top: 27px;
    border-radius: 5px;
}

.second-input-mobile-disabled {
    pointer-events:none;
    user-select: none;
    width: 100%;
    height: 32px;
    border: 1px solid #D6D6D6;
    color: $disabled-text;
    padding: 5px;
    margin-top: 27px;
    border-radius: 5px;
    background-color: $color-gray-lightest;
    background: $color-gray-lightest;
    accent-color: $color-gray-lightest;
}

.second-input:focus, .second-input-mobile:focus {
	outline: none;
	border: 2px solid $primary-color-evenLighter;
}