

.pad {
    background: #FFFFFF;
    border: 2px solid #B3D9FF;
    border-radius: 5px;
    display: block;
    margin: auto;
    margin-bottom: 5px;
    margin-top: 5px;
}

.action-items {
    text-align: center;
}

.description {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #29333C;
}

.description-mobile {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 18px;
    color: #29333C;
}

.description-first-mobile {
    font-family: 'Roboto';
    font-size: 19px;
    line-height: 30px;
    color: #29333C;
    margin-top: 20px;
}

.note {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 17px;
    line-height: 21px;
    margin-top: 20px;
    margin-bottom: 80px;
    color: #29333C;
}

.note-mobile {
    font-family: 'Roboto';
    font-style: italic;
    text-align: center;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 20px;
    color: #29333C;
    margin-top: 20px;
}

.placeholder {
    height: 20px;
}