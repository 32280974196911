@import '../../index.scss';

.header-container {
    width: 100%;
    height: 90px;
    padding: 10px;
}

.header-container-mobile {
    width: 100%;
    height: 60px;
    padding: 10px;
    z-index: 100001;
}

.company-name {
    font-weight: bold;
    font-size: 36px;
    line-height: 42px;
    text-align: center;
    color: $color-gray-dark;
    cursor: pointer;
}

.top_menu {
    position: fixed;
    right: 1vw;
    top: 0;
    transition: all 1s cubic-bezier(0, 1, 1, 1);
    z-index: 111111111;
    background-color: transparent;
}

.top_menu-mobile {
    transition: all 1s cubic-bezier(0, 1, 1, 1);
    background-color: transparent;
    padding-bottom: 50px;
}

.top_menu.hide {
    transform: translate3d(0, -90%, 0)
}

.top_menu.show {
    transform: translate3d(0, 0vh, 0);
    overflow: hidden;
    z-index: 10;
}

.top_menu ul, .top_menu-mobile ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
}

.top_menu li, .top_menu-mobile li {
    cursor: pointer;
    padding: 10px 10px;
    font-size: 15px;
    line-height: 12px;
}

.top_menu li:hover {
    background-color: #007fff;
    color: white;
}

.top_menu .nav_menu {
    border: 4px solid #007fff;
    border-top: 0px;
    background-color: white;
}

.top_menu-mobile .nav_menu {
    border-top: 0px;
}

.top_menu .icon, .top_menu-mobile .icon {
    right: 0;
    top: 50%;
    position: absolute;
    z-index: 100;
    position:relative;
    cursor: pointer;
    border: 4px solid #007fff;
    border-radius: 0px 0px 6px 6px;
    background-color: #007fff;
}

.top_menu .icon path, .top_menu-mobile .icon path{
    fill: white;
}

.top_menu .nav_menu ul li svg, .top_menu-mobile .nav_menu ul li svg{
    height: 32px;
    width: 32px;
    margin-right: 10px;
}

.top_menu .nav_menu ul .complicated svg path:nth-child(3), .top_menu-mobile .nav_menu ul .complicated svg path:nth-child(3){
    fill: white;
}

.top_menu .nav_menu ul li span, .top_menu-mobile .nav_menu ul li span {
    margin-right: 10px;
}

.icon-mobile {
    right: 0;
    top: 70px;
    position: fixed;
    z-index: 100;
    border: 4px solid #007fff;
    border-radius: 6px 0px 0px 6px;
    background-color: #007fff;
}

.icon-mobile-moved {
    right: 74%;
    opacity: 0;
    animation-name: slide-in;
    animation-duration: 0.5s;
    // transform: translateY(0);
}
  
@keyframes slide-in {
    from {
        right: 0;
        opacity: 1;
    }
    to {
        right: 74%;
        opacity: 0;
    }
}

.icon-slide-back {
    right: 0;
    opacity: 1;
    animation-name: slide-out;
    animation-duration: 0.5s;
    // transform: translateY(-100vh);
}

.header-container-mobile:hover .icon-mobile {
    transition: width 0.4s;
  }

@keyframes slide-out {
    from {
        right: 74%;
        opacity: 0;
    }
    to {
        right: 0;
        opacity: 1;
    }
}