@import '../../index.scss';

.button {
	border-radius: 5px;
	border: none;
	text-align: center;
	height: 100vh; /* Magic here */
	display: inline-flex;
	justify-content: center;
	align-items: center;
	box-shadow: 0 0 5px -1px rgba(0,0,0,0.2);
}

.button:hover {
	box-shadow: 0 0 6px -1px rgba(0,0,0,0.6);
    cursor:pointer;
}

.color-white {
	color: $color-white;
}

.color-dark {
	color: $color-gray-mid;
}

.spacious {
	margin: 1%;
}

.spaciousX {
	margin: 3%;
}

.light-blue {
	background: $primary-color;
}

.lighter-blue {
	background: $primary-color-lighter;
}

.dark-blue {
	background: $primary-color-dark;
}

.light-red {
	background: $color-red-light;
}

.dark-red {
	background: $color-red-dark;
}

.normal {
	line-height: 21px;
    height: 35px;
	width: 150px;
}

.flat {
    font-size: 18px;
	line-height: 21px;
	width: fit-content;
	min-width: 130px;
	padding-left: 10px;
	padding-right: 10px;
	height: 35px;
}

.flat-mobile-height {
	height: 27px;
}

.medium {
    min-width: 250px;
    height: 45px;
	justify-content: center;
}

.large {
    width: 100%;
	// width: fit-content;
    height: 40px;
	justify-content: center;
}

.with-icon {
    min-width: 180px;
	width: fit-content;
    height: 45px;
	justify-content: right;
	padding-right: 20px;
}

.with-icon-small {
	min-width: 180px;
	width: fit-content;
    height: 37px;
	justify-content: right;
	padding-right: 20px;
}

.with-icon-smaller {
	min-width: 180px;
	width: fit-content;
    height: 30px;
	justify-content: right;
	padding-right: 20px;
}

.with-icon-long {
	min-width: 250px;
	width: fit-content;
    height: 30px;
	justify-content: right;
	padding-right: 50px;
}

.with-icon-large {
	padding: 10px;
	margin-bottom: 0px;
	margin-top: 0px;
	width: 98%;
    height: 45px;
	justify-content: center;
}

.disabled {
	background: $color-gray-lighter;
}

.someIcon {
	background: url('../../images/some_icon.png') no-repeat right;
	background-size: 20px;
	background: $color-white;
	float: left;
	justify-self: left;
}

.search-icon {
	background: url('../../images/search_icon.png') no-repeat right;
	background-size: 20px;
	background: $color-white;
}

.addIcon {
	background: url('../../images/add_icon.png') no-repeat left;
	background-size: 45px;
	display: inline-block;
    padding: 15px;
    height: 37px;
    width: 37px;
    border-radius: 50%;
    border: none;
	margin-left: 12px;
	margin-right: 12px;
}

.gmailIcon {
	background: url('../../images/gmail_icon.png') no-repeat left;
	// background-size: 45px;
	display: inline-block;
    padding: 15px;
    border-radius: 50%;
    border: none;
	margin-left: 12px;
	margin-right: 12px;
}

.deleteIcon {
	background: url('../../images/delete_icon.png') no-repeat left;
	background-size: 35px;
	display: inline-block;
    padding: 15px;
	margin-left: 12px;
	margin-right: 12px;
    height: 37px;
    width: 37px;
    border-radius: 50%;
    border: none;
}

.text-normal {
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
    line-height: 15px;
	height: fit-content;
}

.text-skinny {
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
}

.small-icon {
	background-size: 26px;
	height: 29px;
	width: 29px;
}

.gray-icon {
	filter: grayscale(1);
	opacity: 0.4;
}