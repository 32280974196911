
.button {
    width: 111px;
    height: 32.5px;
    border-radius: 5px;
    border: none;
}

.very-spacious {
    margin: 1%;
}

.spacious {
    margin-left: 10px;
}

.disabled {
    background: #C4C4C4;
}

.active {
    background: #B3D9FF;
}