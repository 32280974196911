
.icon {
    margin-right: 5px;
}

.smaller-icon {
    margin-right: 3px;
    margin-top: 3px;
}

.placeholder {
    margin-right: 5px;
    width: 29px;
    height: 29px;
    display: inline-block;
    border: 1px solid #c0ddfa;
    margin-bottom: -10px;
    border-radius: 3px;
}

.smaller-placeholder {
    margin-right: 3px;
    // margin-top: 3px;
    width: 30px;
    height: 30px;
    display: inline-block;
    border: 1px solid #a8d3ff;
    margin-bottom: -12px;
    border-radius: 3px;
}