
.field-info {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
}

.field-info-mobile {
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.05em;
}

.modal-footer {
	bottom: 10px;
    width: 95%;
    position: absolute;
    background-color: #f2f9ff;
}