@import '../index.scss';

.container.none {
    display: none;
}
 
.container.block {
    display: block;
    // height: fit-content;
}

.action-items {
    text-align: center;
}

.top-right {
    float: right;
    width: fit-content;
    // position: relative;
    display: inline-flex;
    font-weight: bold;
    font-size: 25px;
    margin-right: 2%;
    // line-height: 60px;
    // margin-bottom: 20px;
    
}

.item {
    box-shadow: 10px 10px  5px rgba(0,0,0,0.15);
    -moz-box-shadow: 10px 10px  5px rgba(0,0,0,0.15);
    -webkit-box-shadow: 10px 10px  5px rgba(0,0,0,0.15);
    -o-box-shadow: 10px 10px  5px rgba(0,0,0,0.15);
    background: $color-white;
    border-radius: 20px;
    padding: 10px;
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 1%;
    margin-bottom: 15px;
    transition: all 0.4s;
    height: fit-content;
}

.item-expandable-item {
    margin-bottom: 0;
}

.item-header-expandable {
    min-height: 45px;
}

.tab-container {
    height: 380px;
    margin-bottom: 10px;
    overflow-y: auto;
    overflow-x: hidden;
}

.tab-container-portrait {
    height: 35vh;
    margin-bottom: 10px;
    overflow-y: auto;
    overflow-x: hidden;
}

.tab-container-tool {
    height: 400px;
    margin-bottom: 10px;
    overflow-y: auto;
    overflow-x: hidden;
}

.tab-container-tool-mobile {
    height: 430px;
    margin-bottom: 10px;
    overflow-y: auto;
    overflow-x: hidden;
}

.item-header-text {
	display: inline-flex;
	font-style: normal;
	font-weight: bold;
	font-size: 24px;
	line-height: 28px;
	color: $color-gray-dark;
	// width: 90%;
    margin-left: 1%;
}

.item-header-text-small {
	// display: inline-flex;
	font-style: normal;
	font-weight: bold;
	font-size: 15px;
	line-height: 0px;
	color: $color-gray-dark;
	// width: 92%;
    margin-left: 1%;
}

.content-half {
    width: 50%;
    height: fit-content;
    padding: 1%;
    display: inline-table;
    text-align: right;
}

.input-container-customer {
    text-align: center;
    top: 50px;
    bottom: 10px;
}

.content-half-ish {
    width: 47%;
    display: inline-block;
    float: left;
}

.content-40 {
    width: 37%;
    display: inline-block;
    float: left;
}

.content-60 {
    width: 57%;
    display: inline-block;
    // float: right;
}

.content-normal {
    width: 100%;
    height: fit-content;
    padding: 1%;
    display: inline-table;
    text-align: right;
}

.placeholder {
    padding-top: 10px;
    background-color: #fdfdfd;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    height: 40px;
}

