

.field:nth-child(1) {
    width: 32%;
    display: inline-table;
    margin: 1%, 5%, 1%, 1%;
}

.field:nth-child(2) {
    width: 32%;
    display: inline-table;
    margin: 1%, 5%, 1%, 0%;
}

.field:nth-child(3) {
    width: 32%;
    display: inline-table;
    margin: 1%, 0%, 1%, 1%;
}

.field-mobile {
    width: 100%;
    display: inline-table;
    margin: 1%, 5%, 1%, 1%;
}

.title {
    font-size: x-large;
    padding: 1%;
    display: block;
    width: 100%;
}

.back-button {
    display: inline-block;
    border-radius: 50%;
    border: none;
    width: 35px;
    height: 35px;
    // margin-left: 10px;
}

.icon-margin {
    margin-top: 8px;
}

.back-button:hover {
    box-shadow: 0 0 6px -1px rgba(0,0,0,0.6);
    cursor:pointer;
    border-radius: 50%;
    border: none;
}