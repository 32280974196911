
.action-items {
    text-align: center;
}

.placeholder {
    padding-top: 10px;
    background-color: #f2f9ff;
    position: fixed;
    top: 0;
    width: 100%;
    overflow: hidden;
    height: 40px;
}