@import './index.scss';

.App {
  font-family: 'Roboto', sans-serif;
  overflow-x: hidden;
	overflow-y: auto;
}

body {
  background-color: $primary-color-lightest;
  color: $color-gray-dark;
  width: 100vw;
  height: 100vh;
}

body.modal-open {
  overflow: hidden;
  position: fixed;
}

html, body {
  overflow-x: hidden;
	overflow-y: auto;
}

.scrollbar
{
	float: left;
	height: 100vh;
	width: 100vw;
	overflow-y: scroll;
}

.force-overflow
{
	height: 100vh;
}


#style-1::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.3);
	border-radius: 5px;
}

#style-1::-webkit-scrollbar
{
	width: 6px;
}

#style-1::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 3 rgba(0,0,0,.3);
	background-color: rgba(179, 217, 255, 1);
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: $primary-color;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.page-margin {
  margin: 2%;
  overflow-x: hidden;
	overflow-y: auto;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.display-block {
  display: block;
}

.display-inlineblock {
  display: inline-block;
}

.icon-normal {
  height: 33px;
  width: 35px;
}

.icon:hover {
  box-shadow: 0 0 6px -1px rgba(0,0,0,0.6);
  cursor:pointer;
}

.align-right {
  text-align: right;
}

.align-left {
  text-align: left;
}

.bold {
  font-weight: bold;
}

.smaller-font {
  font-size: smaller;
}

.with-fit {
  width: fit-content;
}

// MARGINS
.margin-left-one {
  margin-left: 1%;
}

.margin-left-one-minus {
  margin-left: -1%;
}

.margin-right-one {
  margin-right: 1%;
}

.margin-right-one-minus {
  margin-right: -1%;
}

.margin-top-one {
  margin-top: 1%;
}

.margin-top-one-minus {
  margin-top: -1%;
}

.margin-bottom-one {
  margin-bottom: 1%;
}

.margin-bottom-one-minus {
  margin-bottom: -1%;
}

// PADDINGS

.padding-one {
  padding: 1%;
}

.padding-left-one {
  padding-left: 1%;
}

.padding-left-one-minus {
  padding-left: -1%;
}

.padding-right-one {
  padding-right: 1%;
}

.padding-right-one-minus {
  padding-right: -1%;
}

.padding-top-one {
  padding-top: 1%;
}

.padding-top-one-minus {
  padding-top: -1%;
}

.padding-bottom-one {
  padding-bottom: 1%;
}

.padding-bottom-one-minus {
  padding-bottom: -1%;
}

::-webkit-input-placeholder { /* Edge */
  font-style: italic;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  font-style: italic;
}

::placeholder {
  font-style: italic;
}

.scroll-view {
  margin-top: 1%;
	max-height: 400px;
	overflow-y: scroll;
	overflow-x: hidden;
	border-radius: 15px;
}