.data {
    margin: 0.5% 0% 1.5% 1%;
    font-weight: bold;
    font-size: 18px;
}

.data-mobile {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    margin: 12px 0% 10px 10px;
  }
