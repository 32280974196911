@import '../../index.scss';

.container {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    // position: relative;
}

.input {
    display: inline-block;
    width: 55px;
    height: 29px;
    left: 29px;
    top: 977px;

    background: #FFFFFF;
    border: 2px solid #C4C4C4;
}

.input:focus {
	outline: none;
	border: 2px solid $primary-color-evenLighter;
}

.text {
    margin-left: 10px;
    display: inline-block;
    // color: rgb(201, 4, 254)
}

.buttons {
    float: right;
}

.center-buttons {
    margin-top: 10px;
    display: flex;
	align-items: center;
    justify-content: center;
}