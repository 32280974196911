@import '../../index.scss';

.message-board {
    background: $primary-color-evenLighter;
    border-radius: 15px;
    padding: 2% 2% 2% 2%;
}

.icon {
    padding: 1% 1% 1% 1%;
    display: inline-block;
    //top, right, bottom, left
    margin: 0% 7% 3% 2%;
    width: 75px;
    height: 75px;
}

.text-container {
    display: inline-block;
    min-width: 50%;
    max-width: 80%;
    justify-content: center;
    align-items: center;
    // padding-top: 1%;
}

.text, .title {
    font-family: 'Roboto';
    display: inline-block;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
}

.title {
    margin-bottom: 2%;
    font-weight: 600;
}