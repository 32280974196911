@import '../../index.scss';

.filters {
    width: 100%;
	// margin-top: 10px;
	font-style: normal;
	font-weight: 600;
	font-size: 15px;
	line-height: 18px;
	color: $color-gray-dark;
    display: inline-flex; 
}

.filters-narrow {
    width: 79%;
	margin-top: 10px;
	font-style: normal;
	font-weight: 600;
	font-size: 15px;
	line-height: 18px;
	color: $color-gray-dark;
    display: inline-flex; 
	margin-top: 2%;
}

// .filter:nth-child(1), .filter:nth-child(2), .filter:nth-child(3), .filter:nth-child(4), .filter:nth-child(5) {
//     width: 20%;
//     margin-left: 15px;
// 	margin-right: 1%
// }

// .filter-wider:nth-child(1), .filter-wider:nth-child(2), .filter-wider:nth-child(3) {
//     width: 21.5%;
//     margin-left: 1%;
// 	margin-right: 1%
// }

.filter {
	// margin-right: 15px;
	padding-right: 30px;
}

.deleteFiltersIcon {
    display: inline-block;
    height: 37px;
    width: 37px;
    border-radius: 50%;
	margin-left: 22px;
	position: relative;
	top: 35px;
}

.deleteFiltersIcon:hover {
    box-shadow: 0 0 6px -1px rgba(0,0,0,0.6);
    cursor:pointer;
}