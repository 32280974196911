@import '../../index.scss';

.modal {
	visibility: hidden;
	transition: all 0.25s;
	opacity: 0;
}
.modal.open {
	visibility: visible;
	opacity: 1;
}

.modal,
.modal-overlay {
	position: fixed;
	left: 0;
	top: 0;
	height: 100vh;
	width: 100vw;
	z-index: 100;
}

.modal,
.modal-card,
.modal-body {
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-width: 60%;
	max-height: 98%;
	min-height: 30%;
}

.modal-body {
	z-index: 101;
	margin: auto;
	padding: 15px;
	background: $primary-color-lightest;
	border-radius: 15px;
	transition: all 0.4s;
}

.modal-content {
	flex-grow: 1;
	overflow-x: hidden;
	overflow-y: auto;
}

.modal-overlay {
	background: rgba(0, 0, 0, 0.5);
}

.modal-body-container {
	z-index: 101;
	margin: auto;
	overflow: hidden;
	background: $primary-color-lightest;
	border-radius: 15px;
	padding-right: 30px;
	padding-left: 30px;
	padding-top: 15px;
	padding-bottom: 5px;
	transition: all 0.4s;
}

.modal-body-list, .modal-body {
	min-width: 75%;
	max-width: 98%;
	max-height: 98%;
}

.modal-body-customer {
	height: 550px;
	width: 90vw;
	position: relative;
}

.modal-body-customer-portrait {
	height: 580px;
	width: 98vw;
	position: relative;
}

.modal-body-product {
	min-width: 75%;
	max-width: 98%;
	max-height: 98%;
	position: relative;
}

.modal-body-tool {
	height: 570px;
	width: 90vw;
	position: relative;
}

.modal-body-tool-portrait {
	height: 590px;
	width: 98vw;
	position: relative;

}

.modal-body-mobile {
	width: 99vw;
	height: 650px;
	padding-right: 10px;
	padding-left: 10px;
	padding-bottom: 25px;
	position: relative;
}

.modal-body-list-mobile {
	width: 99vw;
	height: 610px;
	padding-right: 10px;
	padding-left: 10px;
	padding-bottom: 25px;
	position: relative;
}

.modal-body-sign {
	width: 78vw;
	height: 445px;
	padding-bottom: 25px;
	position: relative;
}

.modal-body-sign-portrait {
	height: 460px;
	width: 99vw;
	position: relative;
}

.modal-body-sign-mobile {
	width: 99vw;
	height: 650px;
	padding-right: 10px;
	padding-left: 10px;
	padding-bottom: 25px;
	position: relative;
}

.modal-body-email {
	width: 78vw;
	height: fit-content;
	position: relative;
	padding-bottom: 50px;
}

.modal-body-email-portrait {
	height: fit-content;
	width: 99vw;
	padding-bottom: 50px;
	position: relative;
}

.modal-body-email-mobile {
	width: 99vw;
	height: 650px;
	padding-right: 10px;
	padding-left: 10px;
	padding-bottom: 25px;
	position: relative;
}

.modal-header {
	margin-top: 20px;
}

.header-text {
	display: inline-block;
	font-style: normal;
	font-weight: bold;
	font-size: 24px;
	line-height: 28px;
	color: $color-gray-dark;
	width: 92%;
	margin-top: 10px;
}

.header-text-mobile {
	display: inline-block;
	font-style: normal;
	font-weight: bold;
	font-size: 24px;
	line-height: 28px;
	color: $color-gray-dark;
	width: 80%;
	margin-top: 10px;
}

.modal-footer {
	display: inline-flex;
	justify-content: center;
	vertical-align: middle;
	align-items: center;
	width: 100%;
}

.modal-footer-position {
	width: 95%;
	bottom: 10px;
    position: absolute;
	background-color: #f2f9ff;
}

.list-input-container {
	width: 100%;
	overflow: hidden;
	margin-top: 10px;
	font-style: normal;
	font-weight: 600;
	font-size: 15px;
	line-height: 18px;
	color: $color-gray-dark;
}

.list-input-container .search {
	width: 50%;
	float:left; 
}

.list-input-container .category {
	overflow: hidden;
	height: 100%;
}

.content-container {
	// padding-top: 20px;
	// position: relative;
	padding-bottom: 20px;
	// height: 580px;
}


.scroll-view {
	height: 50vh;
	overflow-y: scroll;
	overflow-x:hidden;
	border-radius: 15px;
}

.scroll-view-medium {
	height: 30vh;
	overflow-y: scroll;
	overflow-x:hidden;
	border-radius: 15px;
}

.scroll-view-mobile {
	height: 40vh;
	overflow-y: scroll;
	overflow-x:hidden;
	border-radius: 15px;
}


.center-button {
	justify-content: center;
	margin-left: 35%;
	padding: 0.5%;
}


.headerIcon {
    display: inline-block;
    height: 37px;
    width: 37px;
    border-radius: 50%;
    border: none;
}

.headerIcon:hover {
    box-shadow: 0 0 6px -1px rgba(0,0,0,0.6);
    cursor:pointer;
}

.warningContainer {
	height: 300px;
	text-align: center;
	justify-content: center;
	align-items: center;
}

.warningMessage {
	text-align: center;
	height: 100vh; /* Magic here */
	top: 50%;
	display: inline-block;
	justify-content: center;
	align-items: center;
}

.pane-header {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    color: #29333C;
	margin-bottom: 10px;;
}

.pane-row-item {
	padding: 7px;
}

.pane-menu-icon {
	display: inline-flex;
	width: 25px;
	height: 25px;

}

.pane-menu-title {
	margin-left: 10px;
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	color: #29333C;
	display: inline-flex;
	cursor:	pointer;
	transition: transform 0.2s;
}

.pane-menu-title:hover{
	transform: scale(1.2);
}

.pane-close {
	bottom: 10px;
	left: 10px;
	position: absolute;
}

.pane-close-mobile {
	top: 10px;
	right: 10px;
	position: absolute;
}

.pane-menu-icon-close {
	display: inline-flex;
	width: 16px;
	height: 16px;
}

.pane-menu-title-close {
	margin-left: 10px;
	display: inline-flex;
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #29333C;
}

.slide-in-pane-custom {
    background: #F2F9FF !important;
    border-top: 0px;
    background-color: white;
}

.slide-in-pane-custom-mobile {
    background: #F2F9FF !important;
	padding: 0px !important;
	height: fit-content;
	top: 70px;
	right: 0;
	position: fixed;
	margin-top: -25px;
	border: 4px solid #007fff;
	border-bottom-left-radius: 25px;
	border-top-left-radius: 25px;
    border-right: 0px;
    background-color: white;
}

.disabled {
	opacity: 0.45;
	pointer-events: none;
}

.disabled .pane-menu-title{
	pointer-events: none;
}

.modal_button_float_right{
	margin-left: 70%;
}