@import '../../index.scss';

.modal {
	visibility: hidden;
	transition: all 0.25s;
	opacity: 0;
    overflow: hidden;
}
.modal.open {
	visibility: visible;
	opacity: 1;
}

.modal,
.modal-overlay,
.filter-overlay {
	position: fixed;
	left: 0;
	top: 0;
	height: 100vh;
	width: 100vw;
	z-index: 1000;
    overflow: hidden;
}

.modal,
.modal-card,
.modal-body {
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-width: 60%;
    overflow: hidden;
}

.modal-content {
	flex-grow: 1;
    overflow: hidden;
}

.modal-overlay {
	background: rgba(255, 255, 255, 0.9);
    overflow: hidden;
}

.filter-overlay {
    background: rgba(41, 51, 60, 0.86);
    overflow: hidden;
}

.modal-body {
	z-index: 101;
	margin: auto;
	overflow: hidden;

	background: $primary-color-lightest;
	border-radius: 15px;
	padding-right: 30px;
	padding-left: 30px;
	padding-top: 15px;
	transition: all 0.4s;
}

.filter-description {
    z-index: 1001;
    position: fixed;
    bottom: 50px;
    right: 100px;
    font-size: 15px;
    line-height: 16px;
    color: #29333C;
}

.delete-icon {
    z-index: 10001;
    position: fixed;
    bottom: 110px;
    right: 15px;
}

.delete-description {
    z-index: 1001;
    position: fixed;
    bottom: 130px;
    right: 80px;
    font-weight: 400;
    font-size: 15px;
    line-height: 16px;
    color: #29333C;
}

.content {
    z-index: 2147483647;
    position: absolute;
    bottom: -185px;
    width: 100vw;
    height: 0;
    background: #FFFFFF;
    border-radius: 6px;
    transition: height 1.5s;
    transition-timing-function: cubic-bezier(0, 1, 1, 1);
    padding: 20px;
    padding-bottom: 180px;
    padding-top: 0;
    overflow: scroll;
}

.scrollable-content {
    margin-top: 80px;
    padding-bottom: 270px;
    padding-right: 20px;
    overflow: scroll;
    overflow-x: hidden;
}

.show {
    height: 100%;
}

// .hide {
//     height: 0;
// }

.filter-header {
    z-index: 10001;
    position: fixed;
    padding-top: 20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 90%;
    fill: #fff;
    background: #fff;
}

.filter-header .center-item {
    margin-bottom: 15px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.filter-header .center-title {
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #29333C;
    margin-bottom: 7px;
}