
.tab-header-container {
    margin: 0 1% 0 1%
}

.tab-container {
    height: 380px;
    margin-bottom: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 45px;
}

.tab-container-mobile {
    height: 450px;
    margin-bottom: 10px;
    // padding-bottom: 80px;
    overflow-y: auto;
    overflow-x: hidden;
    max-width: 100%;
}

.tab-container-portrait {
    height: 380px;
    margin-bottom: 10px;
    padding-bottom: 40px;
    overflow-y: auto;
    overflow-x: hidden;
}

.tab-container-customer {
    height: 290px;
    max-width: 100%;
    margin-bottom: 10px;
    overflow-y: auto;
    overflow-x: hidden;
}

.tab-container-tool {
    height: 400px;
    margin-bottom: 10px;
    overflow-y: auto;
    overflow-x: hidden;
}

.tab-container-tool-mobile {
    height: 400px;
    margin-bottom: 10px;
    overflow-y: auto;
    overflow-x: hidden;
}

.tab-container-product {
    height: 380px;
    max-width: 100%;
    margin-bottom: 10px;
    overflow-y: auto;
    overflow-x: hidden;
}

.tab-container-product .currencyPriceCardHolder {
    text-align: center;
    align-items: center;
}

.tab-header {
    justify-content: center;
    text-align: center;
    display: inline-flex;
    width: 25%;
    cursor: pointer;
    transition: all 0.25s;
    line-height: 18px;
}

.tab-header-customer {
    justify-content: center;
    text-align: center;
    display: inline-flex;
    width: 33%;
    cursor: pointer;
    transition: all 0.25s;
    line-height: 18px;
}

.tab-header:hover {
    transition: all 0.1s;
    font-weight: bold;
    font-size: 17px;
    color: rgba(41, 51, 60, 0.86)
}

.active {
    transition: all 0.1s;
    font-weight: bold;
    color: black;
    font-size: 17px;
}

.comment-title {
    margin: 1% 1% 1% 1%;
}

.delivery-title {
    font-size: small;
    font-weight: bold;
}

.row {
    width: 100%;
    // margin: 5px;
    display: inline-flex;
}

.name {
    width: 30%;
}

.data {
    min-width: 20%;
    width: 40%;
    // width: 44%;
}

.delete-button {
    height: 30px;
    width: 20%;
}

.example-row {
    display: inline-flex;
    width: 100%;
    margin: 1.5% 0 1% 5px
}

.example-title {
    margin-left: 4%;
}