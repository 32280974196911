/* COLORS */

$primary-color: rgba(0, 127, 255, 1);
$primary-color-dark: rgba(0, 58, 117, 1);
$primary-color-light: rgba(119, 187, 255, 1);
$primary-color-lighter: rgba(179, 217, 255, 1);
$primary-color-evenLighter: rgba(206, 231, 255, 1);
$primary-color-lightest: rgba(242, 249, 255, 1);

$color-gray-dark: rgba(41, 51, 60, 1);
$color-gray-mid: rgba(41, 51, 60, 0.86);
$color-gray-lighter: rgb(150, 150, 150);
$color-gray-light: rgba(196, 196, 196, 1);
$color-gray-lightest: rgba(219, 219, 219, 1);
$disabled-text: rgba(124, 131, 138, 1);

$color-red-light: rgba(255, 103, 103, 1);
$color-red-dark: rgba(185, 65, 65, 1);

$color-white: rgba(255, 255, 255, 1);