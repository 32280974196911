@import '../../index.scss';

.button-container {
    width: 100%;
    display: block;
    position: relative;
}

.new-ws-button {
    display: inline-flex;
    float: right;
    margin-left: 5.5%;
    margin-bottom: 1%;
}

.add-new-button {
    display: inline-flex;
    float: right;
    margin-left: 5.5%;
    margin-bottom: 1%;
}

.top-right {
    float: right;
    width: fit-content;
    display: inline-flex;
    font-weight: bold;
    font-size: 23px;
    margin-top: 10px;
    margin-right: 20px;
}

.top-right-portrait {
    display: block;
    float: right;
    width: fit-content;
    display: inline-flex;
    font-weight: bold;
    font-size: 23px;
    margin-right: 20px;
}

.top-right-text {
    width: fit-content;
    display: inline-flex;
    float: right;
}

.top-right-text-2 {
    margin-top: 10px;
    display: inline-block;
    min-width: fit-content;
    vertical-align: middle;
    margin-right: 10px;
    line-height: 60px;
    height: 50px;
}

.top-right-text-2-portrait {
    margin-top: 10px;
    display: inline-block;
    min-width: fit-content;
    vertical-align: middle;
    margin-right: 10px;
    line-height: 40px;
    height: 50px;
}

.header-items {
    padding-bottom: 5px;
    display: inline-block;
    width: 100%;
}

.placeholder {
    padding-top: 10px;
    background-color: #f2f9ff;
    position: fixed;
    top: 0;
    width: 100%;
    overflow: hidden;
    height: 40px;
}

.filter-icon-mobile {
    z-index: 10000;
    position: fixed;
    bottom: 10px;
    right: 15px;
}

.overlay-show {
    // width: 100vh;
    // height: 100vh;
    background: rgba(255, 255, 255, 0.541);
    position: fixed;
	left: 0;
	top: 0;
	height: 100vh;
	width: 100vw;
	z-index: 100;
}

.overlay-none {
    background: green;
    z-index: 10000;
    background: rgba(255, 28, 28, 0.541);
    position: absolute;
	// left: 0;
	// top: 0;
	height: 100vh;
    overflow: hidden;
	width: 100vw;
	// z-index: 100;
}

.group-action-select {
    width: 250px;
    height: 45px;
    background: $color-white;
    color: $color-gray-dark;
    margin: 1%;
    border-color: hsl(0, 0%, 80%);
    background-color: hsl(0, 0%, 100%);
    border-radius: 5px;
    padding: 6px;
}

.group-action-select:focus {
    cursor: pointer;
    outline: none;
    border: 1px solid $primary-color-evenLighter;
}

option {
    cursor: pointer;
    color: $disabled-text;
}

option:first-child {
    font-style: italic;
    color: $color-gray-light;
}

.show {
    opacity: 1;
}

.dont-show {
    display: none;
    opacity: 0;
}

.checkmark {
    margin-top: 5px;
    transition: all 0.4s;
}

.dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-content {
    // display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 250px;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .dropdown-content span {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    cursor: pointer;
  }
  
  .dropdown span:hover {background-color: #ddd;}