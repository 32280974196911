@import '../../index.scss';

// BASE TYPES
.input {
	min-width: 5%;
	width: 100%;
	background: $color-white;
    color: $color-gray-dark;
    -webkit-box-sizing: content-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: content-box;    /* Firefox, other Gecko */
    box-sizing: content-box; 
    min-height: 23px;
    border: 1px solid $color-gray-lightest;
    border-radius: 5px;
    padding: 3px;
}

.filter-height {
    min-height: 25px;
}

.checkbox {
    border-radius: 5px;
    cursor: pointer;
    float: left;
    padding: 3px;
    border: 2px solid #D6D6D6;
    border-radius: 6px;
    outline: none;
    width: 25px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    transition-duration: 0.2s;
}

input[type=checkbox]:checked {
    background-color: $primary-color;
    // height:
    background-image: url('../../images/svg/checked-mark.svg');
    background-repeat: no-repeat;
    border-radius: 6px;
    border: 2px solid $primary-color;
}

input[type=checkbox]:disabled {
    border: 2px solid #dbdbdb;
    border-radius: 6px;
    pointer-events: none;
}

input[type=checkbox]:disabled:checked {
    pointer-events: none;
    background-color: #dbdbdb;
    border: 2px solid #dbdbdb;
    background-repeat: no-repeat;
    background-image: url('../../images/svg/checked-mark-disabled.svg');
    border-radius: 6px;
}

.input[type=checkbox]:focus {
	outline: none;
	border: 2px solid $primary-color-evenLighter;
}

.input:focus {
	outline: none;
	border: 1px solid $primary-color-evenLighter;
}

.select {
    max-height: 70px;
    min-width: 10%;
    width: 100%;
    min-height: 23px;
    background: $color-white;
    color: $color-gray-dark;
}

.rounded-border {
    border-radius: 12px;
    padding: 6px;
}

.select:focus {
	outline: none;
	border: 1px solid $primary-color-evenLighter;
}

.text-area {
    resize: none;
    height: 60px;
    font-family: inherit;
    border: 1px solid $color-gray-light;
    border-radius: 5px;
}

.disabled {
    pointer-events:none;
    user-select: none;
    color: $disabled-text;
    background-color: $color-gray-lightest;
    background: $color-gray-lightest;
    accent-color: $color-gray-lightest;
}
// BASE TYPES

// ALIGNMENT
.label-field-header {
    font-weight: bold;
    white-space: nowrap;
    margin-bottom: 13px;
    font-size: 18px;
    overflow-wrap: break-word;
}

.label-size-smaller {
    font-size: 13px;
}

.label-filter {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 7px;
    overflow-wrap: break-word;
}

.label-filter-portrait {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 7px;
    overflow-wrap: break-word;
}

.label-filter-mobile {
    margin-bottom: 7px;
    font-size: 11px;
    line-height: 13px;
}

.label-field-header-mobile {
    font-weight: bold;
    margin-top: 10px;
    padding-bottom: 10px;
    font-size: 13px;
}

.label {
    // white-space: nowrap;
    font-weight: 400;
    font-size: 16px;
    vertical-align: middle;
    overflow-wrap: break-word;
}

.label-mobile {
    font-size: 11px;
    margin-bottom: 5px;
    overflow-wrap: break-word;
}

.expandable {
    display: inline-flex;
	font-style: normal;
	font-size: 24px;
	line-height: 28px;
	color: $color-gray-dark;
    margin-left: 1%;
}
// LABEL

// ALIGNMENT
.align-right-label {
    text-align: right;
}

.align-left-label {
    text-align: left;
    // position: relative;  
}

.align-almost-middle-label {
    text-align: center;
}

.align-middle-label {
    text-align: center;
}

.align-right-bar {
    margin-left: 15px;
}

.align-left-bar {
    margin-right: 10px;
}

.align-almost-middle-bar {
    margin-left: 35%
}

.align-middle-bar {
    margin-left: 43%
}

.align-bottom-bar {
    // height: 200%;
    display: flex;
    flex-wrap: wrap; /* Optional. only if you want the items to wrap */
    justify-content: flex-end; /* For horizontal alignment */
    align-items: flex-end; /* For vertical alignment */
}

.no-border {
    border: none;
}
// ALIGNMENT

// EXTRAS

.full-height {
    min-height: 124px;
}

.button-height {
    margin-top: -9px;
    height: 37.5px;
}

.full-height-extra {
    height: 200px;
}

.bold {
    font-weight: bold;
}

.smaller-font {
    font-size: smaller;
}

.too-long {
    border: 1px solid rgba(253, 18, 18, 0.486);
}

.too-long:focus {
    border: 1px solid rgba(253, 18, 18, 0.486);
}

// EXTRAS