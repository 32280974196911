@import '../../index.scss';

.container {
    margin: 2%;
}

.count {
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 1%;
}

.row-header {
    width: 100%;
    font-weight: 600;
    font-size: large;
    height: 45px;
    min-width: fit-content;
    min-height: fit-content;
    background: $primary-color-lighter;
    border: 2px solid $primary-color-lighter;
    .column-worksheet, .column-timeSpent, .column-externalWork {
        margin-top: 15px;
    }
}

.header-text {
    font-weight: bold;
    font-size: large;
    display: inline-block;
    // margin-right: 45px;
    white-space: nowrap;
}

.header-text-smaller {
    font-weight: bold;
    font-size: medium;
    display: block;
    white-space: nowrap;
}

.arrow {
    // position: absolute;
    float: right;
    margin-top: -8px;
    margin-right: -22px;
    display: inline;
    width: 18px;
    height: 12px;
}

.arrow-portrait {
    float: right;
    // position: absolute;
    margin-top: -28px;
    margin-right: -20px;
    display: inline;
    width: 18px;
    height: 12px;
}

.row {
    user-select: none;
    width: 100%;
    overflow-wrap: break-word;
    min-height: fit-content;
    background: $primary-color-evenLighter;
    border: 2px solid $primary-color-lighter;
}

.row.tall {
    height: 75px;
}

.row.normal-height {
    height: 53px;
}

.row.selected {
    background: $primary-color-lighter;
    border: 2px solid $primary-color-evenLighter;
}

.list-container {
    margin-top: 20px;
    font-size: 16px;
    line-height: 19px;
    width: 100%;
}

.list-container-mobile {
    margin-bottom: 100px;
    margin-top: 20px;
    font-size: 16px;
    line-height: 19px;
    width: 100%;
}

.column-worksheet, .column-timeSpent, .column-externalWork, .column-tool, .column-serviceHistory {
    height: 50px;
    width: 10px;
    vertical-align: middle;
    text-align: left;
    overflow-wrap: break-word;
    margin-right: 1%;
    position: relative;
}

.table-cell {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 200;
    font-size: 16px;
    text-overflow: ellipsis;
    // line-height: 19px;
}


//WORKSHEET COLUMNS END

// .checkColumn {
//     width: 70px;
//     justify-content: center;
// 	align-items: center;
//     text-align: center;
//     margin-top: 11px;
// }


//TIMESPENT COLUMNS
// .column-timeSpent:nth-child(1) {
//     justify-content: center;
//     text-align: center;
//     width: 16%;
//     margin-left: 3%;
//     margin-right: 3%
// }

// .column-timeSpent:nth-child(2) {
//     width: 26%;
// }

// .column-timeSpent:nth-child(3) {
//     width: 15%;
// }

// .column-timeSpent:nth-child(4) {
//     width: 10%;
// }

// .column-timeSpent:nth-child(5) {
//     width: 10%;
// }

// .column-timeSpent:nth-child(6) {
//     width: 7%;
//     justify-content: center;
// 	align-items: center;
//     text-align: center;
//     margin-top: 14px;
// }
// //TIMESPENT COLUMNS END


// //EXTERNAL WORK COLUMNS
// .column-externalWork:nth-child(1) {
//     justify-content: center;
//     text-align: center;
//     width: 17%;
//     margin-left: 3%;
//     margin-right: 3%
// }

// .column-externalWork:nth-child(2) {
//     width: 10%;
// }

// .column-externalWork:nth-child(3) {
//     width: 10%;
// }

// .column-externalWork:nth-child(4) {
//     width: 26%;
// }

// .column-externalWork:nth-child(5) {
//     width: 10%;
// }

// .column-externalWork:nth-child(6) {
//     width: 10%;
// }

// .column-externalWork:nth-child(7) {
//     width: 7%;
//     justify-content: center;
// 	align-items: center;
//     text-align: center;
//     margin-top: 14px;
// }
// //EXTERNAL WORK COLUMNS END


// //TOOL COLUMNS
// .column-tool:nth-child(1) {
//     justify-content: center;
// 	align-items: center;
//     text-align: center;
//     width: 13%;
//     margin-left: 3%;
//     margin-right: 3%;
//     // font-size: 10px;
// }

// .column-tool:nth-child(2) {
//     width: 18%;
// }

// .column-tool:nth-child(3) {
//     width: 18%;
//     vertical-align: middle;
// }

// .column-tool:nth-child(4) {
//     width: 10%;
// }

// .column-tool:nth-child(5) {
//     width: 15%;
// }

// .column-tool:nth-child(6) {
//     width: 7%;
//     justify-content: center;
// 	align-items: center;
//     text-align: center;
//     margin-top: 15px;
//     margin-right: 1%;
//     margin-left: 2%;
// }
// //TOOL COLUMNS END

// //SERVICEHISTORY COLUMNS
// .column-serviceHistory:nth-child(1) {
//     width: 20%;
//     margin-right: 3%;
//     justify-content: center;
//     text-align: center;
// }

// .column-serviceHistory:nth-child(2) {
//     width: 22%;
//     margin-right: 4%
// }

// .column-serviceHistory:nth-child(3) {
//     width: 22%;
//     margin-right: 3%
// }

// .column-serviceHistory:nth-child(4) {
//     width: 10%;
//     justify-content: center;
// 	align-items: center;
//     text-align: center;
//     margin-top: 14px;
// }

// .column-serviceHistory:nth-child(5) {
//     width: 18%;
//     padding-top: -15px;
//     margin-bottom: 15px;
//     // justify-content: center;
// 	// align-items: center;
//     // margin-top: 14px;
//     // vertical-align: middle;
// }

// .checkColumn {
//     width: 70px;
//     justify-content: center;
// 	align-items: center;
//     text-align: center;
//     margin-top: 11px;
// }

//SERVICEHISTORY COLUMNS END

.icon {
    // margin: auto;
    margin-left: 20%;
    transition: transform .2s;
    cursor: pointer;
}

.icon:hover {
    transform: scale(1.2);
}

.clearfix::after {
    content: "";
    clear: both;
    display: table;
  }

.show {
    display: block;
}

.hidden {
    display: none;
}