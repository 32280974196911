@import '../../index.scss';

.back-button {
    display: inline-flex;
    margin-top: 15px;
}

.back-button-mobile {
    display: inline-flex;
}

.back-button:hover {
    box-shadow: 0 0 6px -1px rgba(0,0,0,0.6);
    cursor:pointer;
    border-radius: 50%;
    border: none;
}

.title {
    display: inline-flex;
}

.text {
    margin-top: 15px;
    display: inline-block;
    margin-left: 1%;
    font-size: 36px;
    line-height: 36px;
    vertical-align: middle;
    color: $color-gray-dark;
}

.text-mobile {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;

    color: #29333C;
}

.right {
    display: inline;
    // float: right;
    min-width: 280px;
    position: absolute;
    top: 10px;
    right: 10px;
    // font-size: 10px;
}

.rightButton {
    // position: relative;
    // float: right;
    width: 33px;
    height: 33px;   
}

.rightText {
    font-family: 'Roboto';
    position: relative;
    float: right;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    top: 5px;
}

.none {
    display: inline;
    position: relative;
}

.header-title {

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;
    
    color: #29333C;
}

.header-sum {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 7px;
    color: #29333C;
}

.back-icon-mobile {
    margin-left: 10px;
}

.header-mobile {
    display: block;
    width: 100%;
    // margin-bottom: 10px;
}

.position-fix {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    background: inherit;
    z-index: 100001;
    position: fixed;
    background-attachment: fixed;
}